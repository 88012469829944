<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>
              Edit export
            </h4>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div
                class="form-group col-12 required"
                :class="{ 'is-invalid': errors.has('name') }"
              >
                <label for="name" class="control-label text-capitalize"
                  >Export name</label
                >
                <input
                  id="name"
                  v-model="form.name"
                  v-validate="{ required: true }"
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="Monthly orders"
                />
                <span v-show="errors.has('name')" class="invalid-feedback">{{
                  errors.first("name")
                }}</span>
              </div>
            </div>
            <div class="form-row">
              <div
                class="form-group col-12 required"
                :class="{ 'is-invalid': errors.has('selected_view') }"
              >
                <label for="selected_view" class="control-label text-capitalize"
                  >View</label
                >
                  <Multiselect
                    v-model="selected_view"
                    track-by="id"
                    label="name"
                    :options="views"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    placeholder="Select a view"
                  />
                <span v-show="errors.has('selected_view')" class="invalid-feedback">{{
                  errors.first("selected_view")
                }}</span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-12">
                <p class="mb-0">
                  Segment exported orders:
                </p>
              </div>
              <div
                v-if="Object.keys(form.conditions).length === 0"
                class="form-group col-12"
              >
                <div
                  class="btn btn-block btn-outline-secondary"
                  @click="addCondition"
                >
                  Filter exported data
                </div>
              </div>
            </div>
            <div v-for="(condition, index) in form.conditions" :key="index">
              <condition
                :condition="condition"
                :index="index"
                :model="model"
                @deleteCondition="deleteCondition"
              />
              <div
                v-if="index + 1 == Object.keys(form.conditions).length"
                class="btn btn-block btn-outline-secondary"
                @click="addCondition"
              >
                Add another condition
              </div>
            </div>
          </div>
          <div class="py-1 px-5 bg-light">
            <div class="form-row mt-5">
              <div class="form-group col-12">
                <div class="custom-control custom-switch">
                  <input
                    id="customSwitch1"
                    v-model="form.mailable"
                    type="checkbox"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="customSwitch1"
                    >Setup an automation for this export</label
                  >
                </div>
              </div>
            </div>
            <div v-if="form.mailable" class="form-row">
              <div class="form-group col-12">
                <p class="font-weight-bold mb-0">
                  Export automation
                </p>
                <p class="text-muted">
                  Receive export regularly by email
                </p>
              </div>
              <div
                class="form-group col-12 required"
                :class="{ 'is-invalid': errors.has('email') }"
              >
                <label for="email" class="control-label text-capitalize"
                  >Email to</label
                >
                <input
                  id="email"
                  v-model="form.email"
                  v-validate="{ required: true }"
                  type="email"
                  name="email"
                  class="form-control"
                />
                <span v-show="errors.has('email')" class="invalid-feedback">{{
                  errors.first("email")
                }}</span>
              </div>
              <div
                class="form-group col-md-4 required"
                :class="{ 'is-invalid': errors.has('frequency') }"
              >
                <label for="frequency" class="control-label text-capitalize"
                  >Frequency</label
                >
                <select
                  v-model="form.frequency"
                  name="frequency"
                  class="form-control"
                >
                  <option v-for="frequency in frequencies" :key="frequency">
                    {{ frequency }}
                  </option>
                </select>
                <span
                  v-show="errors.has('frequency')"
                  class="invalid-feedback"
                  >{{ errors.first("frequency") }}</span
                >
              </div>
              <div
                v-if="form.frequency !== 'Daily'"
                class="form-group col-md-4 required"
                :class="{ 'is-invalid': errors.has('send_on') }"
              >
                <label for="send_on" class="control-label text-capitalize"
                  >Send on</label
                >
                <select
                  v-model="form.send_on"
                  name="send_on"
                  class="form-control"
                >
                  <option v-for="time in times" :key="time">
                    {{ time }}
                  </option>
                </select>
                <span
                  v-show="errors.has('send_on')"
                  class="invalid-feedback"
                  >{{ errors.first("send_on") }}</span
                >
              </div>
              <div
                class="form-group col-md-4 required"
                :class="{ 'is-invalid': errors.has('send_at') }"
              >
                <label for="send_at" class="control-label text-capitalize"
                  >Send at</label
                >
                <select
                  v-model="form.send_at"
                  name="send_at"
                  class="form-control"
                >
                  <option v-for="hour in hours" :key="hour">
                    {{ hour }}
                  </option>
                </select>
                <span
                  v-show="errors.has('send_at')"
                  class="invalid-feedback"
                  >{{ errors.first("send_at") }}</span
                >
              </div>
            </div>
          </div>
          <div class="card-footer bg-white">
            <ButtonLoading
              :loading="loading"
              :title="'Save export'"
              :class="'btn btn-secondary float-right'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Condition from "@/components/Automation/Condition.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import Multiselect from "vue-multiselect";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  components: {
    Condition,
    Default,
    ConfigurationNav,
    Multiselect,
    ButtonLoading,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("exports/" + to.params.id).then((response) => {
      next((vm) => {
        vm.form = response.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      form: {
        id: null,
        name: null,
        conditions: [],
        mailable: false,
        email: null,
        frequency: "Weekly",
        send_on: "Monday",
        send_at: "12:00",
        organization_id: null,
        view: ""
      },
      selected_view: "",
      views: [],
      model: "order",
      showFrequency: false,
      frequencies: ["Daily", "Weekly", "Monthly"],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      dates: [
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th",
        "13th",
        "14th",
        "15th",
        "16th",
        "17th",
        "18th",
        "19th",
        "20th",
        "21st",
        "22nd",
        "23rd",
        "24th",
        "25th",
        "26th",
        "27th",
        "28th",
        "Last day",
      ],
    };
  },
  computed: {
    hours() {
      var arr = [],
        i;
      for (i = 7; i < 24; i++) {
        arr.push(i + ":00");
      }
      return arr;
    },
    times() {
      if (this.form.frequency !== "Monthly") {
        return this.days;
      } else {
        return this.dates;
      }
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (
          this.form.frequency == "Monthly" &&
          !this.dates.includes(this.form.send_on)
        ) {
          this.form.send_on = "1st";
        } else if (
          this.form.frequency !== "Monthly" &&
          !this.days.includes(this.form.send_on)
        ) {
          this.form.send_on = "Monday";
        }
      },
    },
  },
  methods: {
    submitForm: function() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if(this.selected_view) {
            this.form.view = this.selected_view.id
          }
          jsonAPI
            .put("/exports/" + this.form.id, this.form)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Export saved",
                type: "success",
              });
              this.loading = false;
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    deleteCondition(index) {
      this.$delete(this.form.conditions, index);
    },
    addCondition() {
      this.form.conditions.push({
        field: "total",
        type: "order",
        operator: "==",
        value: "",
      });
    },
  },
   mounted() {
    jsonAPI
      .get('/configuration/view?page=1')
      .then(response => {
        const view_data = response.data.data;
        const views_array = [];
        view_data.map(function(value) {
          // create array for multiselect
          views_array.push({
            "id": value.id,
            "organization_id": value.organization_id,
            "owner_id": value.owner_id,
            "name": value.data.name
          })
        });
        this.views = views_array;
      })
      .catch(() => {
        window.Bus.$emit("flash-message", {
          text: "Error fetching results",
          type: "error",
        });
      })
  }
};
</script>
